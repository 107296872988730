<template>
  <div id="employees" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base">
      <v-row no-gutters class="titulo ml-10">DIRECTORIO DE EMPLEADOS</v-row>
      <v-row no-gutters justify="center" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Razón Social</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpleados()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <div>
            <label class="typo__label">Búsqueda</label>
            <multiselect
              v-model="seleccionado"
              deselect-label="Eliminar"
              track-by="nombre"
              label="nombre"
              placeholder="Area/Nombre"
              tag-placeholder="Agregar empleado"
              :custom-label="nameWithLang"
              selectLabel="Enter para agregar"
              deselectLabel="Enter para eliminar"
              selectedLabel="Seleccionado"
              :multiple="true"
              :options="employees"
            >
              <span slot="noResult"
                >No se encontraron elementos con la búsqueda</span
              >
              <span slot="noOptions">No existen elementos</span>
            </multiselect>
          </div>
        </v-col>
      </v-row>

      <v-row class="mx-12" v-if="employees.length == 0">
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-card>
            <v-card-text class="text-center">
              <span class="orange white--text px-2 py-1">{{ respuesta }}</span>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
      <v-row
        class="mx-7"
        justify="center"
        v-if="employees && seleccionado.length == 0"
      >
        <v-col
          cols="12"
          class="ml-n4 mt-n4"
          xs="4"
          sm="4"
          md="4"
          lg="4"
          xl="4"
          v-for="(item, i) in employees"
          :key="i"
        >
          <v-card color="#f2f2f2" class="cardDirectory">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span
                class="white--text text-left mt-n7"
                style="font-size: 0.71em !important"
              >
                {{ item.nombre }} {{ item.apellidoPaterno }}
                {{ item.apellidoMaterno }}
              </span>
              <v-spacer></v-spacer>
              <v-menu bottom left transition="scroll-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon class="mt-n7">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list color="amber lighten-5">
                  <v-list-item-title
                    @click="vaciones(item)"
                    class="mx-1"
                    active-class="red"
                    >Vacaciones</v-list-item-title
                  >
                  <v-list-item-title @click="expediente(item)" class="mx-1"
                    >Expediente Digital</v-list-item-title
                  >
                  <v-list-item-title @click="mantenimiento(item)" class="mx-1"
                    >Mantenimiento de Empleado</v-list-item-title
                  >
                </v-list>
              </v-menu>
            </v-card-title>
            <v-row class="mx-1" align-content="start">
              <v-col
                class="12"
                xs="3"
                sm="3"
                md="3"
                lg="3"
                xl="3"
                align-self="start"
              >
                <v-avatar size="80" class="mt-5">
                  <img
                    v-if="item.fotoPerfil != null"
                    :src="item.fotoPerfil"
                    :alt="item.nombre"
                  />
                  <img
                    v-if="item.fotoPerfil == null"
                    src="../../assets/user.png"
                    alt="No tiene"
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9">
                <v-list color="#f2f2f2" class="tagDirectory">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-warehouse</v-icon
                        >{{ item.area.nombreArea }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">fas fa-user</v-icon
                        >{{ item.puesto.nombrePuesto }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">mdi-phone</v-icon
                        >{{ item.telefonoCelular }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">mdi-email</v-icon
                        >{{ item.email }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">fas fa-calendar-day</v-icon
                        >{{
                          format_date(item.fechaAdmision)
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mx-7" justify="center" v-if="seleccionado.length != 0">
        <v-col
          cols="12"
          class="ml-n4 mt-n4"
          xs="4"
          sm="4"
          md="4"
          lg="4"
          xl="4"
          v-for="(item, i) in seleccionado"
          :key="i"
        >
          <v-card color="#f2f2f2" class="cardDirectory">
            <v-card-title
              class="orange lighten-1"
              dense
              style="max-height: 40px !important"
            >
              <span
                class="white--text text-left text mt-n7"
                style="font-size: 0.71em !important"
              >
                {{ item.nombre }} {{ item.apellidoPaterno }}
                {{ item.apellidoMaterno }}
              </span>
              <v-spacer></v-spacer>
              <v-menu bottom left transition="scroll-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon class="mt-n7">mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list color="amber lighten-5">
                  <v-list-item-title
                    @click="vaciones(item)"
                    class="mx-1"
                    active-class="red"
                    >Vacaciones</v-list-item-title
                  >
                  <v-list-item-title @click="expediente(item)" class="mx-1"
                    >Expediente Digital</v-list-item-title
                  >
                  <v-list-item-title @click="mantenimiento(item)" class="mx-1"
                    >Mantenimiento de Empleado</v-list-item-title
                  >
                </v-list>
              </v-menu>
            </v-card-title>
            <v-row class="mx-1" align-content="start">
              <v-col
                class="12"
                xs="3"
                sm="3"
                md="3"
                lg="3"
                xl="3"
                align-self="start"
              >
                <v-avatar size="80" class="mt-5">
                  <img
                    v-if="item.fotoPerfil != null"
                    :src="item.fotoPerfil"
                    :alt="item.nombre"
                  />
                  <img
                    v-if="item.fotoPerfil == null"
                    src="../../assets/user.png"
                    alt="No tiene"
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9">
                <v-list color="#f2f2f2" class="tagDirectory">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-2">fas fa-warehouse</v-icon
                        >{{ item.area.nombreArea }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">fas fa-user</v-icon
                        >{{ item.puesto.nombrePuesto }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">mdi-phone</v-icon
                        >{{ item.empresaId }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">mdi-email</v-icon
                        >{{ item.email }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon small class="mr-3">fas fa-calendar-day</v-icon
                        >{{
                          format_date(item.fechaAdmision)
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      employees: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      seleccionado: [],
      select: null,
      respuesta: "Lo sentimos, necesita seleccionar la razón social.",
    };
  },
  methods: {
    //da formato a fecha
    format_date(fecha) {
      var aux = fecha.substr(0, 10);
      return aux;
    },
    //en ruta a vacaciones del empleado
    vaciones(item) {
      localStorage.empleadoId = item.id;
      this.$router.push("/employeeholidays");
    },
    //retorna el item encontrado de acuerdo al nombre o area
    nameWithLang({ nombre, apellidoPaterno, apellidoMaterno, area }) {
      return `${nombre} ${apellidoPaterno} ${apellidoMaterno}  — [${area.nombreArea}]`;
    },
    //envia a los documentos del empleado
    expediente(item) {
      localStorage.puesto = item.puestoId;
      localStorage.area = item.areaId;
      localStorage.employeeNumber = item.id;
      this.$router.push("/documents");
    },
    //envia al mantenimiento de empleados, es solo lectura
    mantenimiento(item) {
      localStorage.empleado = item.id;
      this.$router.push("/employeemaintenance");
    },
    //obtiene los empleados por empresa, con sus fotos, correos y numeros telefonicos
    obtenerEmpleados() {
      this.employees = [];
      this.seleccionado = [];
      this.show = true;
      axios
        .get(Server + "/empleados", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var todosEmpleados = response.data;
          todosEmpleados.forEach((element) => {
            if (element.empresaId == this.enterprise) {
              //this.documentos = [];
              this.show = true;
              axios
                .get(
                  Server +
                    "/empleadoDocumentos/por-tipo/" +
                    element.id +
                    "/" +
                    19,
                  {
                    headers: {
                      Authorization: localStorage.token,
                    },
                  }
                )
                .then((response) => {
                  this.employees.push({
                    apellidoMaterno: element.apellidoMaterno,
                    apellidoPaterno: element.apellidoPaterno,
                    area: element.area,
                    areaId: element.areaId,
                    cfdiEmpleado: element.cfdiEmpleado,
                    cfdiEmpleadoId: element.cfdiEmpleadoId,
                    comentarioListaNegra: element.comentarioListaNegra,
                    curp: element.curp,
                    datosBancoEmpleado: element.datosBancoEmpleado,
                    datosBancoEmpleadoId: element.datosBancoEmpleadoId,
                    deviceToken: element.deviceToken,
                    email: element.email == null ? "Desconocido" : element.email,
                    empresa: element.empresa,
                    empresaId: element.empresaId,
                    estatus: element.estatus,
                    estatusId: element.estatusId,
                    fechaAceptaTerminosAnticipo:
                      element.fechaAceptaTerminosAnticipo,
                    fechaAdmision: element.fechaAdmision,
                    fechaBaja: element.fechaBaja,
                    fechaNacimiento: element.fechaNacimiento,
                    fechaTransferencia: element.fechaTransferencia,
                    fotoPerfil: response.data.urlDocumento,
                    guidUser: element.guidUser,
                    id: element.id,
                    mobileOS: element.mobileOS,
                    nombre: element.nombre,
                    telefonoCelular:
                      element.telefonoCelular.length > 0
                        ? "Desconocido"
                        : element.telefonoCelular,
                    nss: element.nss,
                    password: element.password,
                    puesto: element.puesto,
                    puestoId: element.puestoId,
                    rfc: element.rfc,
                    sucursal: element.sucursal,
                    sucursalId: element.sucursalId,
                    sueldo: element.sueldo,
                    terminosAnticipo: element.terminosAnticipo,
                  });
                  this.show = false;
                })
                .catch((e) => {
                  if (element.email == null) element.email = "Desconocido";
                  if (element.telefonoCelular == null)
                    element.telefonoCelular = "Desconocido";
                  this.employees.push(element);
                  this.show = false;
                  if (e.response.status == 401) {
                    this.expiration = true;
                  }
                });
            }
          });
          this.employees.sort(function(a, b) {
            if (a.nombre > b.nombre) {
              return 1;
            }
            if (a.nombre < b.nombre) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          if (this.employees.length == 0)
            this.respuesta = "Lo sentimos, no hay ningún empleado registrado.";
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //lista todas las empresas
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerEmpleados();
  },
};
</script>

<style>
.v-application ol,
.v-application ul {
  padding-left: 0px !important;
}
.multiselect__option.multiselect__option--highlight {
  background: rgb(250, 181, 52) !important;
}
.multiselect__tag {
  background: rgba(253, 175, 29, 0.856) !important;
} /*
.multiselect__option--highlight:after{
  
  background: rgba(236, 154, 0, 0.856) !important;
}*/
</style>
